import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect} from "react";

function Counter() {

    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
        query recordsCount {
            recordsConnection(where: { published_at_null: false }) {
                aggregate {
                    count
                }
            }
        }`
    )

    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return(
        <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-sliver">
                phone records listed
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-green-dark">
                {data != undefined && (
                    data.recordsConnection.aggregate.count
                )}
            </dd>
        </div>
    )
}

export default Counter;
