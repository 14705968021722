import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import createUploadLink from "apollo-upload-client/public/createUploadLink";
import Counter from "../components/counter";
import LastUpdate from "../components/lastUpdate";
import TopNine from "../components/topNine";
import TopEight from "../components/topEight";
import RecordsPreviewCounter from "../components/recordsPreviewCounter";

function IndexPage() {

    const cache = new InMemoryCache();
    const client = new ApolloClient({
        cache,
        link: createUploadLink({
            uri: process.env.GATSBY_STAPI
        })
    });

  return (
      <ApolloProvider client={client}>
          <Layout>
              <SEO
                  keywords={[`phone records`, `phone`, `records`, `schallplatten`]}
                  title="Home"
              />

              <div className="bg-lightGrey lg:relative h-full lg:flex">
                  <div className="lg:flex-1 max-w-7xl w-full pt-16 pb-20 center lg:py-15 lg:text-left">
                      <div className="px-4 sm:px-8 xl:pr-16">
                          <h1 className="mt-3 max-w-lg mx-auto text-sliver text-5xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl">
                              <span className="block text-sliver inline">A <span className="text-green-dark">telephone</span> is the</span><br/>
                              <span className="block text-sliver inline">next best thing</span><br/>
                              <span className="block text-sliver inline">to being there</span>
                          </h1>
                          <p className="mt-3 max-w-lg mx-auto text-sliver">
                              Diese Webseite ist den Schallplatten jeglicher musikalischer Richtung gewidmet, die ein Telefon bei der grafischen Gestaltung des Covers benutzen. Unberücksichtigt bleiben Schallplatten bei denen das Wort “Telefon” nur ein Bestandteil des Künstlernamens oder des Musiktitels bildet.</p>
                          <p className="mt-3 max-w-lg mx-auto text-sliver">
                              Ce site est consacré aux vinyls, tous genres confondus, utilisant un téléphone comme élément graphique de la pochette. Ne sont pas pris en considération les disques où le mot “ téléphone “ n’est qu’une composante du nom de l’artiste ou du titre.<br/>
                              <br/>
                              This website is dedicated to records of any musical direction that use a phone as a graphic element for the design of the cover. Records in which the word “phone” is just part of the artist’s name or song will remain unconsidered.
                          </p>
                      </div>
                  </div>
                  <div
                      className="lg:flex-1 w-full bg-lightGrey lg:inset-y-0 lg:right-0 lg:w-1/2 ">
                      <TopNine />
                  </div>
              </div>

              <div className="z-50">
                  <div className="bg-lightGrey pt-12 sm:pt-16 ">
                      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                          <div className="max-w-4xl mx-auto text-center">
                              <h2 className="text-2xl break-words font-extrabold text-green-dark sm:text-3xl">
                                  Die größte Telefonschallplattencoversammlung der Welt
                              </h2>
                              <p className="mt-3 text-gray-500 sm:mt-4">
                                  Sie wollen zu dieser Sammlung beitragen?<br/>Senden Sie eine Nachricht an <a href="mailto:jpk@phone-records.de">jpk@phone-records.de</a> .
                              </p>
                          </div>
                      </div>
                      <div className="mt-10 pb-12 bg-white sm:pb-16">
                          <div className="relative">
                              <div className="absolute inset-0 h-1/2 bg-lightGrey"/>
                              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                  <div className="max-w-4xl mx-auto">
                                      <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                          <Counter />
                                          <LastUpdate />
                                          <RecordsPreviewCounter />
                                      </dl>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <TopEight />
              </div>

          </Layout>
      </ApolloProvider>
  );
}

export default IndexPage;
