import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect} from "react";
import Link from "gatsby-link";

function TopEight() {

    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query GetRecords($limit: Int) {
        records (
        sort: "created_at:desc" 
        limit: $limit, 
        ) {
            id
            artistFirst
            covers {
                url
            }
        }
    }`,
        {
            variables: {
                limit: Number(8)
            }
        }
    )
    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
            <div className="grid grid-cols-1 items-center">
                <div className="mt-8 grid grid-cols-2 md:grid-cols-4 lg:mt-0 lg:grid-cols-8">
                    {data != undefined && data.records.map(record => (
                            record.covers[0] != undefined && ( //process.env.GATSBY_STAPI_GRAPHQL +
                            <Link to={`/entry?id=${record.id}`} >
                                <img key={record.id} className="max-h-50" src={record.covers[0].url} alt=""/>
                            </Link>
                            )
                    ))}
                </div>
            </div>
    )
}

export default TopEight;
