import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect} from "react";
import Link from "gatsby-link";

const random = Math.floor((Math.random() * 1100) + 1)

function TopNine() {

    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query GetRecords($limit: Int, $start: Int) {
        records (
        limit: $limit, 
        start: $start
        ) {
            id
            covers {
                url
            }
        }
    }`,
        {
            variables: {
                limit: Number(36),
                start: Number(random),
            }
        }
    )
    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div className="items-center aspect-w-1 aspect-h-1">
            <div className="object-scale-down inline-grid grid-flow-col grid-cols-3 grid-rows-3">
                {data != undefined && data.records.map((record,index) => {
                    if(record.covers[0] != undefined && index % 4 == 0) { //process.env.GATSBY_STAPI_GRAPHQL +
                        return (
                            <div key={record.id} className="object-scale-down flex-shrink aspect-w-1 aspect-h-1">
                                <Link to={`/entry?id=${record.id}`} >
                                    <img
                                    className="object-scale-down"
                                    src={record.covers[0].url} alt=""/>
                                </Link>
                            </div>
                        )
                    }
                }
                )}
            </div>
        </div>
    )
}

export default TopNine;
